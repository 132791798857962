import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { TestQuestionsList } from '../api/Api';
import { useNavigate, useParams } from 'react-router';
import AdSense from '../../components/ads/AdSense';

export const TestSeriesDetail = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [globalTimer, setGlobalTimer] = useState(60); // Total timer for all questions
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answers, setAnswers] = useState({});
  const [showSummary, setShowSummary] = useState(false);

  const questionRef = useRef();
  const { id } = useParams();
  const { data, isLoading, error } = useQuery(['testSeriesDetail', id], () => TestQuestionsList(id));

  // Load saved answers from localStorage when component mounts
  useEffect(() => {
    const savedAnswers = JSON.parse(localStorage.getItem('testAnswers')) || {};
    setAnswers(savedAnswers);
  }, []);

  // Start global timer based on total questions (1 minute per question)
  useEffect(() => {
    if (data && data.length > 0) {
      setGlobalTimer(data.length * 60); // 60 seconds for each question
    }
  }, [data]);

  // Global Timer Countdown
  useEffect(() => {
    if (globalTimer <= 0) {
      handleSubmit(); // Automatically submit when timer reaches 0
      return;
    }

    const interval = setInterval(() => {
      setGlobalTimer((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [globalTimer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  // Handle Answer Selection
  const handleAnswerSelection = (answer) => {
    setSelectedAnswer(answer);
    const newAnswers = { ...answers, [currentQuestion]: answer };
    setAnswers(newAnswers);
    localStorage.setItem('testAnswers', JSON.stringify(newAnswers)); // Save to localStorage
  };

  // Move to next question or submit
  const handleNextQuestion = () => {
    if (!selectedAnswer) {
      const newAnswers = { ...answers, [currentQuestion]: 'Not Attempted' };
      setAnswers(newAnswers);
      localStorage.setItem('testAnswers', JSON.stringify(newAnswers));
    }

    if (currentQuestion < data.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      handleSubmit(); // Submit when it's the last question
    }
    setSelectedAnswer(null); // Reset selected answer
  };

  const handleSubmit = () => {
    setShowSummary(true); // Show summary modal
  };

  const navigate = useNavigate();

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading questions...</div>;
  if (!data || !data[currentQuestion]) return <div>No questions available.</div>;

  const totalQuestions = data.length;
  const currentQuestionData = data[currentQuestion];

  return (
    <div className="h-auto bg-custom-gray p-2">
      <div className="flex justify-center">
        <div className="flex-1 w-full max-w-4xl" ref={questionRef}>
          <div className="p-4 md:p-6 bg-white rounded-lg shadow-lg space-y-4">
            <h1 className="flex justify-between text-sm md:text-2xl font-bold text-gray-800">
              <span>Question {currentQuestion + 1} of {totalQuestions}</span>
              <span>Total Time Remaining: {formatTime(globalTimer)}</span>
            </h1>

            <div className="p-3 md:p-4 bg-blue-100 rounded-lg">
              <p className="text-sm md:text-lg text-gray-700">
                <span dangerouslySetInnerHTML={{ __html: currentQuestionData.question_text }} />
              </p>
            </div>

            <div className="space-y-2 md:space-y-3">
              {['Ans_A', 'Ans_B', 'Ans_c', 'Ans_D'].map((option, index) => (
                <p
                  key={index}
                  onClick={() => handleAnswerSelection(currentQuestionData[option])}
                  className={`text-sm md:text-lg text-gray-800 hover:bg-gray-100 p-2 rounded-lg cursor-pointer transition duration-200 
                  ${selectedAnswer === currentQuestionData[option] ? 'bg-blue-300' : ''}
                  ${answers[currentQuestion] === currentQuestionData[option] ? 'bg-custom-yellow text-white' : ''}`} // Green background for saved answer
                >
                  {currentQuestionData[option]}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Navigation buttons */}
      <div className="bg-white flex flex-wrap p-3 justify-center w-full max-w-4xl mx-auto mt-5 rounded-lg space-y-2 sm:space-y-0">
        <button
          onClick={() => setCurrentQuestion((prev) => Math.max(prev - 1, 0))}
          className="p-2 md:p-3 border border-custom-yellow mx-1 rounded hover:bg-custom-yellow transition duration-200"
          disabled={currentQuestion === 0}
        >
          Previous
        </button>

        {/* Grid layout for question numbers */}
        <div className="flex flex-wrap justify-center gap-1">
          {Array.from({ length: totalQuestions }).map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentQuestion(index)}
              className={`p-2 md:p-3 border border-custom-yellow rounded hover:bg-custom-yellow transition duration-200 hover:text-white ${currentQuestion === index ? 'bg-custom-yellow text-white' : ''} 
              
               ${answers[index] ? 'bg-custom-yellow text-white' : 'bg-gray-300'}
              `}

            >
              {index + 1}
            </button>
          ))}
        </div>

        <button
          onClick={currentQuestion < totalQuestions - 1 ? handleNextQuestion : handleSubmit} // Change to submit on last question
          className="p-2 md:p-3 border border-custom-yellow mx-1 rounded hover:bg-custom-yellow transition duration-200"
          disabled={currentQuestion === totalQuestions - 1 && !selectedAnswer}
        >
          {currentQuestion < totalQuestions - 1 ? 'Next' : 'Submit'}
        </button>
      </div>

      {/* Summary modal */}
      {showSummary && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 md:p-6 rounded-lg max-w-lg w-full">
            <h2 className="text-lg font-bold">Summary of Answers</h2>
            {/* Scrollable container for summary */}
            <ul className="flex flex-col mt-4 space-y-2 max-h-64 overflow-y-auto sm:max-h-80 md:max-h-96 lg:max-h-[28rem]">
              {data.map((questionData, index) => {
                const userAnswer = answers[index];
                const correctAnswer = questionData.correct_answer;
                const isCorrect = userAnswer === correctAnswer;

                return (
                  <li
                    key={index}
                    className={`flex flex-col sm:flex-row sm:justify-between sm:items-center p-2 border rounded-md ${isCorrect ? 'text-green-600 border-green-300' : 'text-red-600 border-red-300'
                      }`}
                  >
                    <span className="font-medium text-gray-700 sm:w-1/4">Q {index + 1}:</span>
                    <span className="text-gray-600 sm:w-1/3">{userAnswer || 'Not answered'}</span>
                    <span className="text-green-600 sm:w-1/3">{correctAnswer || 'Not answered'}</span>
                  </li>
                );
              })}
            </ul>


            <div className="mt-4">
              <p className="font-semibold">Total Correct: {data.reduce((total, questionData, index) => {
                return answers[index] === questionData.correct_answer ? total + 1 : total;
              }, 0)}</p>
              <p className="font-semibold">Total Incorrect: {data.reduce((total, questionData, index) => {
                return answers[index] !== questionData.correct_answer ? total + 1 : total;
              }, 0)}</p>
            </div>

            <button
              onClick={() => {
                setShowSummary(false);
                navigate('/test-series/test/');
                localStorage.clear();
              }}
              className="mt-4 p-2 bg-blue-500 text-white rounded">
              Close
            </button>
          </div>
        </div>
      )}

      {/* Ads section */}
      <div className="mt-5">
        <AdSense />
      </div>
    </div>
  );
};
