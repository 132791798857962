import React from 'react';
import { physics } from '../../../components/home/Image';
import { useQuery } from 'react-query';
import { PhysicsAllClass } from './api/Api';
import { useNavigate } from 'react-router-dom';
import AdSense from '../../../components/ads/AdSense';

export const PhysicsClass = () => {
  const { data } = useQuery('physicsclass', PhysicsAllClass);
  const navigate = useNavigate();

  const handleNavigate = (id,class_name) => {
    navigate('/physics', { state: { id,class_name } });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Physics Classes</h1>
      <div className="flex flex-wrap -mx-4">
        {data?.data?.map((item) => (
          <div key={item.id} className="w-full md:w-1/2 xl:w-1/3 p-4">
            <div className="bg-white rounded shadow-md p-4 cursor-pointer" onClick={() => handleNavigate(item.id,item?.class_name)}>
              <img src={physics} alt={`Class_${item.class_name}_Image`} className="w-full h-64 object-cover mb-4" />
              <p className="text-2xl font-bold mb-2">{item.class_name}</p>
            </div>
          </div>
        ))}
      </div>
      <div>
        <AdSense/>
      </div>
    </div>
  );
};
