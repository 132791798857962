import React from 'react'
import AdSense from '../../../components/ads/AdSense';

export const TopicName = ({ topic, onSelectedChapterId ,subjectName,setToggle}) => {

    const handleClick = (id,topicName) => {
        onSelectedChapterId(id,topicName);
        setToggle(false);
      };
    
      return (
      
        <div className="w-96 h-screen bg-white p-4 shadow-lg overflow-scroll overflow-x-hidden">
        {/* <h1 className="text-2xl font-bold mb-4 text-center">Chapters</h1> */}
        <ul className="space-y-3">
            <h1 className="text-sm font-bold mb-4">{subjectName}</h1>
            {topic?.map((chapterData,index) => (
              <div key={chapterData.id} className="chapter-list mb-4">
                <h2
                  className="text-sm w-full font-semibold mb-2 cursor-pointer text-gray-600 truncate"
                  onClick={() => handleClick(chapterData.id,chapterData.topics)}
                >
                 {index+1} {chapterData.topics}
                </h2>
              </div>
            ))}
          </ul>
          <div>
            <AdSense/>
          </div>
        </div>
      );
    };
