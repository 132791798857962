import React, { Suspense } from 'react'
import { Routes,Route } from 'react-router'
import { MiniProject } from './MiniProject'
import { ProjectDetails } from './ProjectDetails'
import AdSense from '../components/ads/AdSense'

export const Project = () => {
  return (
    <Suspense>
      <AdSense/>
    <Routes>
        <Route path="mini-project" element={<MiniProject/>}/>
        <Route path="project-detail/:id" element={<ProjectDetails/>}/>
    </Routes>
</Suspense>
  )
}
