import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useQuery } from 'react-query';
import { ProjectListDetails } from './Services';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router';
import AdSense from '../components/ads/AdSense';

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-700"
            onClick={onClick}
        >
            <FaArrowRight size={20} />
        </button>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-700"
            onClick={onClick}
        >
            <FaArrowLeft size={20} />
        </button>
    );
};

const baseURL = process.env.REACT_APP_BASE_URL;

export const ProjectDetails = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

const {id} = useParams()
console.log(id,"id")
const { data, isLoading, error } = useQuery(['projects', id], () => ProjectListDetails(id), {
    enabled: !!id,  // Ensures that the query only runs when the id is available
  });

const [imageSet, setImage] = useState(null)
// console.log(data?.title)
   
    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading projects</div>;
    }

    return (
        <div className='w-custom-width m-auto'>
            <h1 className='text-center text-3xl font-bold text-black bg-gray-200 mt-5 mb-5 p-5 rounded-lg'>
                Project Details
            </h1>
            <div>
                {/* {data?.map((product) => ( */}
                    <div key={data.id} className='flex flex-col md:flex-row justify-evenly mb-8'>
                        <div className='md:w-1/2 mb-4'>
                            <img 
                                src={`${baseURL}/${imageSet? imageSet:data?.image}`} 
                                alt='Project representation' 
                                className='w-full h-auto rounded-lg shadow-md' 
                            />
                            <div className='mt-5'>
                                <Slider {...settings}>
                                    {data?.image_gallery.map((images) => (
                                        <div key={images.id} onClick={()=>setImage(images?.image)}>
                                            <img 
                                                src={`${baseURL}/${images.image}`} 
                                                alt='Slide' 
                                                className='w-full h-auto rounded-lg shadow-md' 
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                        <div className='md:w-1/2'>
                            <div className='bg-white p-4 rounded-lg shadow-md'>
                                <h2 className='font-bold text-xl mb-2'>{data.title}</h2>
                                <p className='text-gray-700'>
                                    <strong>Details:</strong>
                                    <p className="react-answer">
                                     <span dangerouslySetInnerHTML={{ __html: data.description_new }} />
                                    </p>
                                </p>
                                <div>
                                    <AdSense/>
                                </div>
                            </div>
                        </div>
                    </div>
              
            </div>
        </div>
    );
};
