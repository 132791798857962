import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Quill snow theme
import 'quill-better-table/dist/quill-better-table.css'; // Table plugin CSS
import Quill from 'quill';
import BetterTable from 'quill-better-table';
import { AddTestQuestionsList } from '../../api/Api';
// import axios from 'axios'; // Ensure axios is imported

Quill.register({
  'modules/better-table': BetterTable
}, true);

// Enable table and other necessary formats in Quill
const modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'size': [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'align': [] }],
    ['link', 'image'],
    ['clean'],
    [{ 'table': 'insert-table' }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike',
  'color', 'background', 'script', 'list', 'bullet', 'indent', 'align', 'link', 'image', 'table'
];

export const AddQuestionsAdmin = ({ id }) => {
  const quillRef = useRef();

  // Initialize formData as a list (array of objects)
  const [formData, setFormData] = useState([{
    question_text: "",
    Ans_A: "",
    Ans_B: "",
    Ans_c: "",
    Ans_D: "",
    correct_answer: "",
    test: id // Adding the test id to each question
  }]);

  // Handle input changes for answers and other fields
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData]; // Clone the array
    updatedFormData[index][name] = value; // Update the specific question object
    setFormData(updatedFormData); // Update state with the new list
  };

  const handleQuillChange = (value, index) => {
    const updatedFormData = [...formData]; // Clone the array
    updatedFormData[index].question_text = value; // Update the specific question object
    setFormData(updatedFormData); // Update state with the new list
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await AddTestQuestionsList(formData);
      console.log(result, "result");
      console.log("payload", formData);
    } catch (error) {
      console.error("Error submitting questions:", error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-8 p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Add New Question {id}</h2>

      {/* Loop through formData (even if it's just one question for now) */}
      {formData.map((question, index) => (
        <div key={index}>
          <label className="block mb-2 font-bold">Question Text</label>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={question.question_text}
            onChange={(value) => handleQuillChange(value, index)}
            modules={modules}
            formats={formats}
            className="mb-4"
            placeholder="Enter question details here..."
          />

          <label className="block mb-2 font-bold">Answer A</label>
          <input
            type="text"
            value={question.Ans_A}
            onChange={(e) => handleChange(e, index)}
            name='Ans_A'
            className="w-full mb-4 p-2 border rounded"
            placeholder="Answer A"
          />

          <label className="block mb-2 font-bold">Answer B</label>
          <input
            type="text"
            value={question.Ans_B}
            onChange={(e) => handleChange(e, index)}
            name='Ans_B'
            className="w-full mb-4 p-2 border rounded"
            placeholder="Answer B"
          />

          <label className="block mb-2 font-bold">Answer C</label>
          <input
            type="text"
            value={question.Ans_c}
            onChange={(e) => handleChange(e, index)}
            name='Ans_c'
            className="w-full mb-4 p-2 border rounded"
            placeholder="Answer C"
          />

          <label className="block mb-2 font-bold">Answer D</label>
          <input
            type="text"
            value={question.Ans_D}
            onChange={(e) => handleChange(e, index)}
            name='Ans_D'
            className="w-full mb-4 p-2 border rounded"
            placeholder="Answer D"
          />

          <label className="block mb-2 font-bold">Correct Answer</label>
          <select
            value={question.correct_answer}
            onChange={(e) => handleChange(e, index)}
            name='correct_answer'
            className="w-full mb-4 p-2 border rounded"
          >
            <option value="">Select correct answer</option>
            <option value={question.Ans_A}>{question.Ans_A}</option>
            <option value={question.Ans_B}>{question.Ans_B}</option>
            <option value={question.Ans_c}>{question.Ans_c}</option>
            <option value={question.Ans_D}>{question.Ans_D}</option>
          </select>
        </div>
      ))}

      <button
        onClick={handleSubmit}
        className="bg-blue-500 text-white px-4 py-2 rounded-lg"
      >
        Submit Question
      </button>
    </div>
  );
};


