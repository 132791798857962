import React from 'react'
import { Home } from './Home'
// import { Navbar } from '../navbar/Navbar'
import { TimeMnagement } from './TimeMnagement'
// import { AllCourses } from './AllCourses'
// import { BoardSyllabus } from './BoardSyllabus'
// import { CompetitiveSyllabus } from './CompetitiveSyllabus'
// import { LatestNews } from './LatestNews'
import { Review } from './Review'
import { WebTechnology } from './course/WebTechnology'
import { PythonTechnology } from './course/PythonTechnology'
import { JavaTechnology } from './course/JavaTechnology'
import AdSense from '../ads/AdSense'
import { TestListSlider } from '../../testseries/test/TestListSlider'
// import { UnaniAiapget } from './entrancetest/aiapget/UnaniAiapget'
// import { EntranceTest } from './entrancetest/EntranceTest'

export const MainContainer = () => {
  return (
    <div className='flex flex-col'>
      {/* <Navbar/> */}
      {/* <Home/> */}
      <TimeMnagement />
      <div>
        <h1 className='text-center text-4xl font-custom-fontnew p-3 font-bold'>All competitive Exam Practice set</h1>
        <TestListSlider />
      </div>
      <div className="ads-container">
        <AdSense />
        {!window.google && <div className="ads-placeholder"></div>}
      </div>
      <WebTechnology />
      <div className="ads-container">
        <AdSense />
        {!window.google && <div className="ads-placeholder"></div>}
      </div>
      <PythonTechnology />
      <div className="ads-container">
        <AdSense />
        {!window.google && <div className="ads-placeholder"></div>}
      </div>
      <JavaTechnology />
      {/* <AllCourses/> */}
      {/* <UnaniAiapget/> */}
      {/* <BoardSyllabus/> */}
      {/* <EntranceTest/> */}
      {/* <LatestNews/> */}
      <Review />
    </div>
  )
}
