import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router'
import { JobUpdate } from './JobUpdate'
import { JobDetails } from './JobDetails'
import { JobListAdmin } from './jobadmin/JobListAdmin'

export const JobUpdatScreen = () => {
  return (
    <Suspense>
        <Routes>

        <Route path='job-update' element={<JobUpdate/>}/>
        <Route path='job-details/:id' element={<JobDetails/>}/>



        <Route path='job-admin/:accessToken' element={<JobListAdmin/>}/>
        </Routes>
    </Suspense>
  )
}
