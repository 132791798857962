import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { TopicName } from './TopicName';
import { ObjectiveQuestions, PhysicsChapterName } from '../api/Api';
import { NotFound } from '../../../404page/NotFound';
import AdSense from '../../../components/ads/AdSense';

export const Questions = () => {
  const location = useLocation();
  const classId = location?.state?.id;
  const notesRef = useRef(null);
  
  // Scroll to top when the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [chapterIdSelect, setChapterIdSelect] = useState(null);
  const [chapterTopic, setChapterTopicSelect] = useState(null);
  const [toggle, setToggle] = useState(false); // State for sidebar toggle

  const handleSelectedChapterId = (id, topicName) => {
    setChapterIdSelect(id);
    setChapterTopicSelect(topicName);
    if (notesRef.current) {
      notesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Fetching questions based on selected chapter
  const { data, isLoading, isError, error } = useQuery(
    ['data', chapterIdSelect],
    () => ObjectiveQuestions(chapterIdSelect),
    { enabled: !!chapterIdSelect }
  );
  console.log(data,"data")
  // Fetching chapter names based on classId
  const { data: topic } = useQuery(['chapter'], () => PhysicsChapterName(classId));

  if (isLoading) return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  if (isError) return <div className="flex justify-center items-center min-h-screen">Error: {error.message}</div>;

  return (
    <div className="flex">
      {/* Sidebar for Chapters */}
      <div
        className={`w-64 bg-white shadow-lg z-50 lg:block ${
          toggle ? 'block absolute h-full' : 'hidden'
        }`}
      >
        <TopicName
          topic={topic}
          onSelectedChapterId={handleSelectedChapterId}
          subjectName={location?.state?.subject_name}
          setToggle={setToggle}
        />
      </div>

      {/* Main Content for Notes */}
      <div className="flex-grow min-h-screen bg-gray-100 mt-5" ref={notesRef}>
        <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-0 mx-auto">
          {/* Toggle Button for Mobile View */}
          <h1 className='lg:hidden block cursor-pointer mb-4 text-blue-600' onClick={() => setToggle(!toggle)}>
            {toggle ? 'Close Chapter' : 'Open Chapter'}
          </h1>

          <p className="breadcrumb text-gray-600 mb-4 mt-5">
            Home » <span className="breadcrumb-link text-blue-600"> {location?.state?.class_name} </span> » {chapterTopic}
          </p>
          <h2 className="react-question text-4xl font-semibold mb-4">{data?.chapter_name}</h2>
         { chapterIdSelect?"":(<TopicName
          topic={topic}
          onSelectedChapterId={handleSelectedChapterId}
          subjectName={location?.state?.subject_name}
          setToggle={setToggle}
        />)}
          {/* {
            Array.isArray(data) && data?.length === 0 ? (
              <TopicName
              topic={topic}
              onSelectedChapterId={handleSelectedChapterId}
              subjectName={location?.state?.subject_name}
              setToggle={setToggle}
            />
            ):""
          } */}
         

          {/* Questions and Answers */}
          {Array.isArray(data) && data?.length === 0 ? (
            <NotFound />
          ) : (
            <div className="qa-container mb-6">
              {data?.map((alldata, index) => (
                <div key={index} className="react-answer text-gray-600 mb-4">
                  <p>
                    <span dangerouslySetInnerHTML={{ __html: alldata?.objective_notes }} />
                  </p>
                  <div><AdSense /></div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
