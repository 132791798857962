import React, { Suspense }from 'react'
import { Route, Routes } from 'react-router-dom'
import {Javascript, Angular, Chart, Css, D3js, Express, Html, Jquery, Moment, Nest, Next, Node, Reactjs, Redux, Three, Typescript, Underscore, Vue} from './index'
// import AdSense from '../../../../components/ads/AdSense'

export const AppScreenjs = () => {
  return (
    <div>
      {/* <AdSense/> */}
    <Suspense>
      <Routes>
        <Route path='/react' element={<Reactjs />} />
        <Route path='/javascript' element={<Javascript />} />
        <Route path='/angular' element={<Angular />} />
        <Route path='/chartjs' element={<Chart />} />
        <Route path='/css' element={<Css />} />
        <Route path='/D3js' element={<D3js />} />
        <Route path='/expressjs' element={<Express />} />
        <Route path='/html' element={<Html />} />
        <Route path='/jquery' element={<Jquery />} />
        <Route path='/momentjs' element={<Moment />} />
        <Route path='/nestjs' element={<Nest />} />
        <Route path='/nextjs' element={<Next />} />
        <Route path='/nodejs' element={<Node />} />
        <Route path='/redux' element={<Redux />} />
        <Route path='/threejs' element={<Three />} />
        <Route path='/typescript' element={<Typescript />} />
        <Route path='/underscorejs' element={<Underscore />} />
        <Route path='/vue' element={<Vue />} />
      </Routes>
    </Suspense>
  </div>
  )
}



