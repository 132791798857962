import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ChemistryChapter } from './ChemistryChapter';
import { ChemistryChapterName, ChemistryNotes } from '../physics/api/Api';
import { NotFound } from '../../../404page/NotFound';
import AdSense from '../../../components/ads/AdSense';

export const Chemistry = () => {
  const location = useLocation();
  const chemistryId = location?.state?.id;
  const notesRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [chapterIdSelect, setChapterIdSelect] = useState(null);

  const handleSelectedChapterId = (id) => {
    setChapterIdSelect(id);
    if (notesRef.current) {
      notesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { data, isLoading, isError, error } = useQuery(
    ['chemistry', chapterIdSelect], 
    () => ChemistryNotes(chapterIdSelect), 
    { enabled: !!chapterIdSelect }
  );

  const { data: chapter } = useQuery(['chemistry'], () => ChemistryChapterName(chemistryId));

  const [toggle,setToggle] = useState(false)

  if (isLoading) return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  if (isError) return <div className="flex justify-center items-center min-h-screen">Error: {error.message}</div>;

  return (
    <div>
    <div className="flex">
      {/* Sidebar for Chapters */}
      <div
        className={`w-64 bg-white shadow-lg z-50 lg:block ${
          toggle ? 'block absolute h-full' : 'hidden'
        }`}
      >
        <ChemistryChapter 
          chapter={chapter}
          onSelectedChapterId={handleSelectedChapterId}
          setToggle={setToggle}
        />
      </div>
      
      {/* Main Content for Notes */}
      <div className="flex-grow min-h-screen bg-gray-100 p-4">
        <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6 mx-auto">
        <h1 className='lg:hidden block' onClick={()=>setToggle(!toggle)}>chapter</h1>
          <p className="breadcrumb text-gray-600 mb-4">
            Home » <span className="breadcrumb-link text-blue-600"> {location?.state?.class_name} </span> » {data?.chapter_name}
          </p>
          <h2 className="react-question text-4xl font-semibold mb-4">{data?.chapter_name}</h2>
          {
            Array.isArray(data?.note) && data?.note.length === 0 ?(<NotFound/>):(

          <div ref={notesRef} className="qa-container mb-6">
            {data?.note?.map((alldata, index) => (
              <div key={index} className="react-answer text-gray-700 mb-4">
                <span dangerouslySetInnerHTML={{ __html: alldata.note }} />
              </div>
            ))}
          </div>
            )
        }
        </div>
      </div>
    </div>
    <div>
      <AdSense/>
    </div>
    </div>
  );
  };
  
