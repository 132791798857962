import React, { useState } from 'react';
import { AddTestListSubCategory } from '../../api/Api';

export const AddSubTestListAdmin = ({id}) => {
  console.log(id,"category")
  const [formData, setFormData] = useState({
    sub_category: '',
    subject_name: '',
    meta_title: '',
    meta_description: '',
    meta_tags: '',
    category: id
  });
console.log(formData,"formdata")
  const [error, setError] = useState(null); // State to manage errors
  const [success, setSuccess] = useState(null); // State to manage success messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await AddTestListSubCategory(formData);
      console.log('Form submitted successfully:', result);
      setSuccess('Sub Test List added successfully!');
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit the form. Please try again.');
      setSuccess(null); // Clear success message
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Add Sub Test List {id}</h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {success && <div className="text-green-500 mb-4">{success}</div>}
      
      <form onSubmit={handleSubmit}>
        {/* Sub Category */}
        <div className="mb-4">
          <label htmlFor="sub_category" className="block text-gray-700 font-semibold mb-2">
            Sub Category
          </label>
          <input
            type="text"
            id="sub_category"
            name="sub_category"
            value={formData.sub_category}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            placeholder="Enter sub category"
            required
          />
        </div>

        {/* Subject Name */}
        <div className="mb-4">
          <label htmlFor="subject_name" className="block text-gray-700 font-semibold mb-2">
            Subject Name
          </label>
          <input
            type="text"
            id="subject_name"
            name="subject_name"
            value={formData.subject_name}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            placeholder="Enter subject name"
          />
        </div>

        {/* Meta Title */}
        <div className="mb-4">
          <label htmlFor="meta_title" className="block text-gray-700 font-semibold mb-2">
            Meta Title
          </label>
          <input
            type="text"
            id="meta_title"
            name="meta_title"
            value={formData.meta_title}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            placeholder="Enter meta title"
          />
        </div>

        {/* Meta Description */}
        <div className="mb-4">
          <label htmlFor="meta_description" className="block text-gray-700 font-semibold mb-2">
            Meta Description
          </label>
          <textarea
            id="meta_description"
            name="meta_description"
            value={formData.meta_description}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            placeholder="Enter meta description"
          />
        </div>

        {/* Meta Tags */}
        <div className="mb-4">
          <label htmlFor="meta_tags" className="block text-gray-700 font-semibold mb-2">
            Meta Tags
          </label>
          <input
            type="text"
            id="meta_tags"
            name="meta_tags"
            value={formData.meta_tags}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            placeholder="Enter meta tags"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white font-semibold px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};