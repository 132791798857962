import axios from "axios"

const baseURL = process.env.REACT_APP_BASE_URL;
// console.log(baseURL, "baseURL");

export const Reactdata = async (page = 1, pageSize = 10) => {
    try {
        const response = await axios.get(`${baseURL}/syllabus/react/`, {
            params: { page, page_size: pageSize }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data');
    }
}



export const JavascriptData = async (page=1,pageSize=10)=>{
    console.log("baseurl",baseURL)
    try {
        const response = await axios.get(`${baseURL}/syllabus/javascript/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}


export const HtmlData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/html/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}


export const CssData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/css/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const AngularData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/angular/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}



export const TypescriptData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/typescript/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const ReduxData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/redux/`,{
            params:{page, page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const VuetData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/vue/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const JqueryData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/jquery/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const BootstrapData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/bootstrap/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const NodeData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/node/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const ExpressData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/express/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const NextData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/next/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const NestData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/nest/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const MomentData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/moment/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const AxiosData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/axios/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const D3jsData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/d3js/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const ThreejsData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/threejs/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const ChartjsData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/chartjs/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const UnderscorejsData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/underscorejs/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const PythonData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/python/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const DjangoData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/django/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const FlaskData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/flask/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const NumpyData = async (page=1,pageSize=10)=>{
    try {
        console.log("hi")
        const response = await axios.get(`${baseURL}/syllabus/numpy/`,{
            params:{page,page_size:pageSize}
        });
        console.log(response,"res")
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const PandasData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/pandas/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const MetaplotlibData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/metaplotlib/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const RequestsData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/requests/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const CeleryData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/celery/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const TensorflowpytorchData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/tensorflowpytorch/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const JavaData = async (page=1,pageSize=10)=>{
    console.log("res")
    try {
        const response = await axios.get(`${baseURL}/syllabus/java/`,{
            params:{page,page_size:pageSize}
        });
        console.log(response,"res")
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const SpringData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/spring/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const HibernateData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/hibernate/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const ApachestrutsData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/apachestruts/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}



export const JunitData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/junit/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const ApachemavenData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/apachemaven/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const SparkframeworkData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/metaplotlib/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const CData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/requests/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}

export const CPlusplusData = async (page=1,pageSize=10)=>{
    try {
        const response = await axios.get(`${baseURL}/syllabus/c++/`,{
            params:{page,page_size:pageSize}
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching data')
    }
}






