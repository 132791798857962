// import React from 'react'
import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Quill snow theme
import 'quill-better-table/dist/quill-better-table.css'; // Table plugin CSS
import Quill from 'quill';
import BetterTable from 'quill-better-table';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { JobDetailsPost } from '../api/Api';
// import Quill from 'quill';

// Register BetterTable with Quill

Quill.register({
  'modules/better-table': BetterTable
}, true);

// Enable table and any other necessary formats in Quill
const modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'size': [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'align': [] }],
    ['link', 'image'],
    ['clean'],
    [{ 'table': 'insert-table' }],
  ],

  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike',
  'color', 'background', 'script', 'list', 'bullet', 'indent', 'align', 'link', 'image', 'table'
];

export const JobAdd = ({ jobId, selectedJob }) => {
  const [editorContent, setEditorContent] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const quillRef = useRef();
  const queryClient = useQueryClient();
  console.log(selectedJob, "selectedJob")


  // Mutation for posting job details
  const mutation = useMutation((payload)=>JobDetailsPost(selectedJob,payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(['jobDetails', selectedJob]); // Invalidate query to refetch the job details
    },
    onError: (error) => {
      console.error('Error posting job details:', error);
    }
  });

  // Handle form submission
  const handleSubmit = () => {
    const payload = {
      job_details: editorContent,
      meta_title: metaTitle,
      meta_description: metaDescription,
      job_name: selectedJob,
    };
    console.log(payload, "payload")
    // Trigger mutation to post the job details
    mutation.mutate(payload);
  };

  // if (isLoading) {
  //   return <div>Loading job details...</div>;
  // }

  return (
    <div className="max-w-4xl mx-auto mt-8 p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Add Job Details {selectedJob}</h2>

      <label className="block mb-2 font-bold">Meta Title</label>
      <input
        type="text"
        value={metaTitle}
        onChange={(e) => setMetaTitle(e.target.value)}
        className="w-full mb-4 p-2 border rounded"
        placeholder="Meta Title"
      />

      <label className="block mb-2 font-bold">Meta Description</label>
      <textarea
        value={metaDescription}
        onChange={(e) => setMetaDescription(e.target.value)}
        className="w-full mb-4 p-2 border rounded"
        placeholder="Meta Description"
      ></textarea>

      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={editorContent}
        onChange={setEditorContent}
        modules={modules}
        formats={formats}
        className="mb-4"
        placeholder="Enter job details here..."
      />

      <button
        onClick={handleSubmit}
        className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        disabled={mutation.isLoading}
      >
        {mutation.isLoading ? 'Submitting...' : 'Submit Job Details'}
      </button>
    </div>
  );
};  