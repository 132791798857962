import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL;

export const ProjectList = async()=>{
    try {
        const response = await axios.get(`${baseUrl}/syllabus/miniproject`)
        return response.data
    } catch (error) {
        throw error;
    }
};



export const ProjectListDetails = async(id)=>{
    // console.log(id,"idsdf")
    try {
        const response = await axios.get(`${baseUrl}/syllabus/miniproject/${id}/`)
        console.log(response)
        return response.data
    } catch (error) {
        throw error;
    }
};