import React from 'react';
import Slider from 'react-slick';
import { useQuery } from 'react-query';
import { TestListCategory } from '../api/Api';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-700"
      onClick={onClick}
    >
      <FaArrowRight size={20} />
    </button>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="absolute left-2 top-1/2 transform -translate-y-1/2 z-10 bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-700"
      onClick={onClick}
    >
      <FaArrowLeft size={20} />
    </button>
  );
};

export const TestListSlider = () => {
    const { data } = useQuery('test-list-home', TestListCategory);

    // const handleClick = (item) => {
    //   setTestName(item);
    // };
  
    // Slider settings
    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3, // You can adjust the number of slides shown based on screen size
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    return (
      <div>
        <div className="flex-grow">
          <div className="w-full max-w-4xl rounded-lg mx-auto">
            {/* Breadcrumb */}
            <p className="breadcrumb text-gray-600 mb-4"></p>
  
            {/* Slider Section */}
            <Slider {...sliderSettings}>
              {data?.map((item) => (
                <div key={item.id} className="px-3">
                  <a href='/test-series/test'>
                  <div
                    className="bg-custom-gray rounded-lg shadow-custom-shadow p-5 transform transition-all duration-300 group w-full">
                    {/* Content Section */}
                    <div className="m-4 text-left">
                      <p className="text-sm font-bold text-gray-600 transition-colors duration-300 group-hover:text-gray-800">
                        {item.name}
                      </p>
  
                      <button className="mt-4 w-full px-4 py-2 bg-custom-yellow text-white rounded-lg hover:bg-indigo-700 transition-all duration-300">
                        Start Test
                      </button>
                    </div>
                  </div>
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  };