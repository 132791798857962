import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { DeleteTestListSubCategory, TestListSubCategory } from '../api/Api';
import AdSense from '../../components/ads/AdSense';
import { Modal } from '../../jobupdate/jobadmin/Modal';
import { AddSubTestListAdmin } from './addformtest/AddSubTestListAdmin';
import { MeApi } from '../../components/account/AccountApi';

export const TestListSubAdmin = () => {
    // const {id} = useParams()
    const [isJobModalOpen, setIsJobModalOpen] = useState(false);

    const { data: userdata } = useQuery(['token'], () => MeApi());

    const openJobModal = () => setIsJobModalOpen(true);
    const closeJobModal = () => setIsJobModalOpen(false);
    const { id } = useParams();
    // console.log(id, "testPapaer");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);
    // const [toggle, setToggle] = useState(false)

    const { data, isLoading, error } = useQuery(
        ['testpaper', id],
        () => TestListSubCategory(id),
        {
            enabled: !!id, // Ensures API call is made only when id is available
        }
    );
    console.log(data, "data")
    // const navigate = useNavigate()
    // const handleClick = (item)=>{
    //     navigate(`/test-series/test-paper-admin/${item.id}/`)
    // }

    const handleDelete = async (questionId) => {
        try {
          const result = await DeleteTestListSubCategory(questionId); // Assuming this is the API call for deletion
          if (result.status === 200) {
            console.log('Question deleted successfully');
            // Optionally, you can refresh the question list or update the UI after deletion.
          } else {
            console.log('Failed to delete the question');
          }
        } catch (error) {
          console.error('Error deleting question:', error);
        }
      };
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading test papers: {error.message}</div>;

    return (
        <div className='flex flex-col lg:flex-row'>

            {/* Test Papers List */}
            {
                userdata && userdata.user.user_type === 2 ? (
                    <div className="flex-grow min-h-screen bg-gray-100 p-4">
                <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6 mx-auto">
                    {/* <h1 className='lg:hidden block' onClick={() => setToggle(!toggle)}>  »»Test List</h1> */}

                    <p className="breadcrumb text-gray-600 mb-4">
                        {/* Home » <span className="breadcrumb-link text-blue-600">location?.state?.class_name</span> » */}
                    </p>
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
                        onClick={openJobModal}
                    >
                        Add Test Category
                    </button>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 p-4'>
                        {data?.map((item) => (
                            <div
                                key={item.id}
                                className="bg-custom-gray rounded-lg shadow-custom-shadow transform transition-all duration-300 group w-full"
                            >
                                {/* Image Section */}
                                {/* <div
                                    className="h-[120px] flex justify-around items-center w-full"
                                    style={{ background: 'linear-gradient(to top, #ddd6f3, #faaca8)' }}
                                >
                                    <img
                                        src={Reactjs}
                                        alt={item.name}
                                        className="w-[50px] h-[50px] mt-4 rounded-lg sm:w-[60px] sm:h-[60px] lg:w-[70px] lg:h-[70px]"
                                    />
                                    <img
                                        src={Reactjs}
                                        alt={item.name}
                                        className="w-[50px] h-[50px] mt-4 rounded-lg sm:w-[60px] sm:h-[60px] lg:w-[70px] lg:h-[70px]"
                                    />
                                </div> */}

                                {/* Content Section */}
                                <div className="m-4 text-left">
                                    <p className="text-lg font-bold text-gray-600 transition-colors duration-300 group-hover:text-gray-800">
                                        {item.sub_category}
                                    </p>
                                    <p className="text-sm text-gray-600">{item.syllabus_name}</p>
                                    <p className="text-sm text-gray-600">{item.description}</p>
                                    <p className="text-sm text-gray-600">{item.number_of_question}</p>
                                    <a href={`/test-series/test-paper-admin/${item.id}/`}>
                                        <button className="mt-4 w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-300">
                                            Start Test {item?.exam_sub_category}
                                        </button>
                                    </a>
                                    <button className="mt-4 w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-300" onClick={()=>handleDelete(item?.id)}>
                                            Delete
                                        </button>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>

                <Modal isOpen={isJobModalOpen} onClose={closeJobModal} >
                    <AddSubTestListAdmin id={id}/>

                    <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded" onClick={closeJobModal}>
                        Cancel
                    </button>

                </Modal>
            </div>
                ):(
                    <div>
                      <h1 className="text-3xl font-bold text-center py-4">Unauthorized</h1>
                    </div>
                  )
            }
            
            <AdSense />
        </div>
    );
};
