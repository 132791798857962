import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { TestListCategory } from '../api/Api'
import { useNavigate, useParams } from 'react-router'

export const TestListAdmin = () => {
    const {data} = useQuery('test-list-home', TestListCategory)

    const params = useParams()
    console.log(params,"params")
    // console.log(data,"data")
    const navigate = useNavigate()
    const handleClick = (item)=>{
        console.log(item)
        navigate(`/test-series/test-sub-admin/${item.id}/`)
    }


    // useEffect(()=>{

    // },[])
  return (
    <div>
         <div className="flex-grow min-h-screen">
                <div className="w-full max-w-4xl rounded-lg  mx-auto">
                    {/* <h1 className='lg:hidden block' onClick={() => setToggle(!toggle)}>  »»Test List</h1> */}

                    <p className="breadcrumb text-gray-600 mb-4">
                        {/* Home » <span className="breadcrumb-link text-blue-600">location?.state?.class_name</span> » data?.chapter_name */}
                    </p>
                    {/* <h2 className="react-question text-4xl font-semibold mb-4">data?.chapter_name</h2> */}
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 p-4'>
                        {data?.map((item) => (
                            <div
                                key={item.id}
                                className="bg-custom-gray rounded-lg shadow-custom-shadow transform transition-all duration-300 group w-full"
                                onClick={()=>handleClick(item)}
                            >
                                {/* Image Section */}
                                {/* <div
                                    className="h-[120px] flex justify-around items-center w-full"
                                    style={{ background: 'linear-gradient(to top, #ddd6f3, #faaca8)' }}
                                >
                                    <img
                                        // src={Reactjs}
                                        alt={item.name}
                                        className="w-[50px] h-[50px] mt-4 rounded-lg sm:w-[60px] sm:h-[60px] lg:w-[70px] lg:h-[70px]"
                                    />
                                    <img
                                        // src={Reactjs}
                                        alt={item.name}
                                        className="w-[50px] h-[50px] mt-4 rounded-lg sm:w-[60px] sm:h-[60px] lg:w-[70px] lg:h-[70px]"
                                    />
                                </div> */}

                                {/* Content Section */}
                                <div className="m-4 text-left">
                                    <p className="text-sm font-bold text-gray-600 transition-colors duration-300 group-hover:text-gray-800">
                                        {item.name}
                                    </p>
                                  
                                    {/* <a href={`/test-series/test-start/${item.id}`}> */}
                                        <button className="mt-4 w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-300">
                                            Start Test 
                                        </button>
                                    {/* </a> */}
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
    </div>
  )
}
