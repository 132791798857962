import React from 'react';
import { Reactjs } from '../components/home/Image';
import { useQuery } from 'react-query';
import { ProjectList } from './Services';
import AdSense from '../components/ads/AdSense';
const baseURL = process.env.REACT_APP_BASE_URL;

export const ProjectCard = () => {
    // Sample project data for 10 cards
    const { data, isLoading, error } = useQuery('projects', ProjectList)
    // console.log(data, "data")
    return (
        <div className='w-11/12 m-auto'>
            <h2 className='text-center text-3xl font-bold font-custom-fontnew p-5 bg-gradient-to-r from-blue-600 to-purple-600 text-white mt-5 mb-10 rounded-lg'>
                Project Cards
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {data?.map((project) => (
                    <div key={project.id} className="card bg-white border border-gray-200 shadow-md hover:shadow-xl rounded-lg overflow-hidden transition-shadow duration-300 ease-in-out transform hover:scale-105">
                        <div>

                            <img src={`${baseURL}${project.image}`} alt={project.title} className="w-full h-40 object-cover" />
                        </div>

                        <div className='p-4'>
                            <div className='flex justify-between items-center mb-2'>
                                <p className="font-semibold text-lg text-gray-800">{project.title}</p>
                                <p className="text-blue-500 font-bold">{project.price}</p>
                            </div>
                            <a href={`/project/project-detail/${project.id}`}> <button className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md transition-colors duration-200">
                                View Project
                            </button></a>
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <AdSense/>
            </div>
        </div>
    );
};
