import React, { useEffect } from "react";
// import "./index.css"; // Make sure this file contains the custom styles

const GoogleTranslate = () => {
  useEffect(() => {
    const addGoogleTranslateScript = () => {
      if (!document.querySelector("[src='https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit']")) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        script.async = true;

        script.onerror = () => {
          console.error("Failed to load Google Translate script.");
        };

        document.body.appendChild(script);
      }

      window.googleTranslateElementInit = () => {
        try {
          new window.google.translate.TranslateElement(
            {
              pageLanguage: "en",
              includedLanguages: "en,hi,ur",
              layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            },
            "google_translate_element"
          );
        } catch (error) {
          console.error("Google Translate initialization failed:", error);
        }
      };
    };

    addGoogleTranslateScript();
  }, []);

  return (
    <div className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-3 text-center">
        🌐 Choose language
      </h2>
      <div
        id="google_translate_element"
        className="flex justify-center items-center"
      >

      </div>
    </div>
  );
};

export default GoogleTranslate;
