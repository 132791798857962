import React, { useState } from 'react';
import AdSense from '../../components/ads/AdSense';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { DeleteTestPaperList1, TestPaperList1 } from '../api/Api';
import { Modal } from '../../jobupdate/jobadmin/Modal';
import { AddTestPaperList } from './addformtest/AddTestPaperList';
import { NotFound } from '../../404page/NotFound'
import { MeApi } from '../../components/account/AccountApi';

export const TestPaperList = () => {
    const [isJobModalOpen, setIsJobModalOpen] = useState(false);
    const { data: userdata } = useQuery(['token'], () => MeApi());

    const openJobModal = () => setIsJobModalOpen(true);
    const closeJobModal = () => setIsJobModalOpen(false);
    const { id } = useParams();

    const { data, isLoading, error } = useQuery(
        ['testpaper', id],
        () => TestPaperList1(id),
        {
            enabled: !!id, // Ensures API call is made only when id is available
        }
    );

    const handleDelete = async (paperListId) => {
        try {
            const result = await DeleteTestPaperList1(paperListId); // Assuming this is the API call for deletion
            if (result.status === 200) {
                console.log('Question deleted successfully');
                // Optionally, you can refresh the question list or update the UI after deletion.
            } else {
                console.log('Failed to delete the question');
            }
        } catch (error) {
            console.error('Error deleting question:', error);
        }
    };
    if (isLoading) return <div>Loading...</div>;
    // if (error) return <div>Error loading test papers: {error.message}</div>;

    return (
        <div className='flex flex-col lg:flex-row'>
            {/* Test Papers List */}

            {
                userdata && userdata.user.user_type === 2?(
                    <div className="flex-grow min-h-screen bg-gray-100 p-4">
                <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6 mx-auto">
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
                        onClick={openJobModal}
                    >
                        Add Test Paper
                    </button>

                    {error ? <NotFound /> : <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 p-4'>
                        {data?.map((item) => (
                            <div
                                key={item.id}
                                className="bg-custom-gray rounded-lg shadow-custom-shadow transform transition-all duration-300 group w-full"
                            >
                                {/* Content Section */}
                                <div className="m-4 text-left">
                                    <p className="text-lg font-bold text-gray-600 transition-colors duration-300 group-hover:text-gray-800">
                                        {item.practice_set}
                                    </p>
                                    <p className="text-sm text-gray-600">{item.syllabus_name}</p>
                                    <p className="text-sm text-gray-600">{item.description}</p>
                                    <p className="text-sm text-gray-600">{item.number_of_question}</p>
                                    <a href={`/test-series/test-question-admin/${item.id}`} className='flex justify-between'>
                                        <button className="mt-4 w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-300">
                                            Start Test {item.exam_sub_category}
                                        </button>

                                    </a>
                                    <button className="mt-4 w-full px-2 py-1 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-300" onClick={() => handleDelete(item?.id)}>
                                        delete
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>}

                </div>

                {/* Render the modal only when isJobModalOpen is true */}
                <Modal isOpen={isJobModalOpen} onClose={closeJobModal}>
                    <AddTestPaperList id={id} />
                    <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded" onClick={closeJobModal}>
                        Cancel
                    </button>
                </Modal>
                {/* )} */}
            </div>
                ):(
                    <div>
                      <h1 className="text-3xl font-bold text-center py-4">Unauthorized</h1>
                    </div>
                  )
            }
            
            <AdSense />
        </div>
    );
};
