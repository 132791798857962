import React from 'react'

export const ChemistryChapter = ({ chapter, onSelectedChapterId,setToggle }) => {
  const handleClick = (id) => {
    onSelectedChapterId(id);
    setToggle(false)
  };

  return (
    <div className="w-64 h-screen bg-white p-4 shadow-lg overflow-scroll overflow-x-hidden">
      <h1 className="text-2xl font-bold mb-4 text-center">Chapters</h1>
      <ul className="space-y-3">
        {chapter?.map((chapterData,index) => (
          <li key={chapterData.id}>
            <h2
              className="text-sm text-gray-700 font-medium cursor-pointer hover:text-blue-600"
              onClick={() => handleClick(chapterData.id)}
            >
             {index+1} {chapterData.che_chapter_name}
            </h2>
          </li>
        ))}
      </ul>
    </div>
  );
    };
    