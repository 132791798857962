import React, { useState } from 'react'

export const Enquary = () => {
  const [enquaryData, setEnquaryData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  })

  const handleChange = (e) => {

    setEnquaryData({ ...enquaryData, [e.target.name]: [e.target.value] })
  }
  const onSubmit = (e) => {
    e.preventDefault()
    console.log(enquaryData)
  }

  // const cardCreate = ()=>{
  //   for()
  // }

  return (
    <div>
      <div className='w-custom-width m-auto'>
        <div className='mt-10'>
          <form onSubmit={onSubmit} className='w-96 flex flex-col justify-center items-center bg-custom-pink m-auto font-custom-fontnew'>
            <h1 className='text-center font-custom-fontnew p-4 text-3xl'>Enquiry Form</h1>
            <div className='flex flex-col mb-2 p-2 w-custom-width'>
              <label className='mb-2'>Name</label>
              <input type='text' className='border border-spacing-3 p-2 w-full' name='name' value={enquaryData.name} placeholder='Name' onChange={handleChange} />
            </div>
            <div className='flex flex-col mb-2 p-2 w-custom-width'>
              <label className='mb-2'>Email</label>
              <input type='email' className='border border-spacing-3 p-2 w-full' name='email' value={enquaryData.email} placeholder='Name' onChange={handleChange} />
            </div>
            <div className='flex flex-col mb-2 p-2 w-custom-width'>
              <label className='mb-2'>Mobile number</label>
              <input type='number' className='border border-spacing-3 p-2 w-full' name='phone' value={enquaryData.phone} onChange={handleChange} placeholder='Name' />
            </div>

            <div className='flex flex-col mb-2 p-2 w-custom-width'>
              <label className='mb-2'>Message</label>
              <textarea name="message" value={enquaryData.message} onChange={handleChange}>Message</textarea>
            </div>

            <div className='flex flex-col mb-2 p-2 w-custom-width'>
              <input type='submit' placeholder='submit' className='bg-custom-gray p-2 font-custom-fontnew font-bold text-2xl w-1/2 m-auto' />

            </div>

          </form>
        </div>
      </div>
    </div>
  )
}
