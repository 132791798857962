import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LoginApi } from './AccountApi';

export const Login = () => {
    // Validation schema using Yup
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    // Initial form values
    const initialValues = {
        email: '',
        password: '',
    };

    // Form submission handler
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const result = await LoginApi(values);
            console.log(result, "result");
            console.log(result.data,"reults")
            localStorage.setItem('authToken', result.data.access);
            localStorage.setItem('refresh', result.data.refresh);
           
            console.log('Form submitted', values);
        } catch (error) {
            console.error('Login failed', error);
        } finally {
            setSubmitting(false);  // Set submitting to false after the API call
        }
    };

    return (
        <div className="py-16">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
                            <div
                                className="hidden lg:block lg:w-1/2 bg-cover"
                                style={{
                                    backgroundImage: `url('https://images.unsplash.com/photo-1546514714-df0ccc50d7bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=667&q=80')`,
                                }}></div>

                            <div className="w-full p-8 lg:w-1/2">
                                <h2 className="text-2xl font-semibold text-gray-700 text-center">Skillvibes.in</h2>
                                <p className="text-xl text-gray-600 text-center">Welcome back!</p>

                                <div className="mt-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Email Address</label>
                                    <Field
                                        name="email"
                                        className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                                        type="email"
                                    />
                                    <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-1" />
                                </div>

                                <div className="mt-4">
                                    <div className="flex justify-between">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
                                        <a href="/" className="text-xs text-gray-500">Forget Password?</a>
                                    </div>
                                    <Field
                                        name="password"
                                        className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                                        type="password"
                                    />
                                    <ErrorMessage name="password" component="div" className="text-red-500 text-xs mt-1" />
                                </div>

                                <div className="mt-8">
                                    <button
                                        type="submit"
                                        className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600"
                                        disabled={isSubmitting}  // Disable button when submitting
                                    >
                                        {isSubmitting ? 'Logging in...' : 'Login'}
                                    </button>
                                </div>

                                <div className="mt-4 flex items-center justify-between">
                                    <span className="border-b w-1/5 md:w-1/4"></span>
                                    <a href="/register" className="text-xs text-gray-500 uppercase">or sign up</a>
                                    <span className="border-b w-1/5 md:w-1/4"></span>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
