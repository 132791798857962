import axios from 'axios'


const baseUrl = process.env.REACT_APP_BASE_URL





export const JobNameListShow = async () => {
    try {
        const response =await axios.get(`${baseUrl}/api/job/api/categories/`)
        console.log(response.data, "res")
        return response.data
    } catch (error) {
        throw error
    }

}


export const JobNameAdds = async(data)=>{
    console.log(data,"data")
    try {
        const response = await axios.post(`${baseUrl}/api/job/job-name-list`,data)
        return response.data
    } catch (error) {
        throw error
    }
}



export const JobDetailsShow = async(id)=>{
    console.log(id,"id")
    try {
        const response = await axios.get(`${baseUrl}/api/job/job-name-detail/${id}`)
        console.log(response,"response")
        return response.data
    } catch (error) {
        throw error
    }
}




export const JobDetailsPost = async(id,data)=>{
    console.log(data,"data")

    try {
        const response = await axios.post(`${baseUrl}/api/job/job-name-detail/${id}/`,data)
        return response.data
    } catch (error) {
        throw error
    }
}



export const JobDetailsEdits = async(id,data)=>{
    console.log(data,"data")

    try {
        const response = await axios.put(`${baseUrl}/api/job/job-name-detail/${id}/`,data)
        return response.data
    } catch (error) {
        throw error
    }
}