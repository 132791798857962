import React, { useState } from 'react';
import { Modal } from './Modal'; // Import the Modal component
import { JobAdd } from './JobAdd'; // Import your JobAdd component
import { useQuery } from 'react-query';
import { JobNameListShow } from '../api/Api'; // API for fetching the jobs
import { JobDetailsEdit } from './JobDetailsEdit';
import { JobNameAdd } from './JobNameAdd';

export const JobListAdmin = () => {
    const [isJobModalOpen, setIsJobModalOpen] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null); // Track selected job for editing or adding details
    const [editJobs, setEditJobs] = useState(); // Track selected job for editing or adding details

    const openJobModal = () => setIsJobModalOpen(true);
    const closeJobModal = () => setIsJobModalOpen(false);

    // Open Details modal and set the selected job
    const openDetailsModal = (job) => {
        console.log(job,"ajgsj")
        setSelectedJob(job);
        setIsDetailsModalOpen(true);
    };
    const closeDetailsModal = () => {
        setSelectedJob(null);
        setIsDetailsModalOpen(false);
    };


    const openEditModal = (editId) => {
        console.log(editId,"ajgsj")
        setEditJobs(editId);
        setIsEditModalOpen(true);
    };
    const closeEditModal = () => {
        setSelectedJob(null);
        setIsEditModalOpen(false);
    };

    const { data: jobs, isLoading, error } = useQuery("admin-job", JobNameListShow); // Fetch job data

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error fetching jobs</div>;

    return (
        <div className="w-custom-width mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4 text-center">Job List Admin</h1>

            {/* Button to trigger Add Job modal */}
            <button
                className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
                onClick={openJobModal}
            >
                Add Job
            </button>

            {/* Add Job Modal */}
            <Modal isOpen={isJobModalOpen} onClose={closeJobModal}>
                <JobNameAdd/>
                <button className="mt-4 bg-green-500 text-white px-4 py-2 rounded" onClick={closeJobModal}>
                    Save Job
                </button>
                <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded" onClick={closeJobModal}>
                    Cancel
                </button>
            </Modal>

            {/* Job List Table */}
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
                    <thead>
                        <tr className="bg-gray-100 text-left">
                            <th className="px-4 py-2">Job</th>
                            <th className="px-4 py-2">Add details</th>
                            <th className="px-4 py-2">Edit</th>
                            <th className="px-4 py-2">Delete</th>
                        </tr>
                    </thead>
                    {jobs.map((job) => (
                        <tbody>
                            {
                                job.job_names.map((item) => (
                                    <tr key={item?.id} className="border-t">

                                        <td className="px-4 py-2">{item?.name} <span className='text-custom-yellow'>{job?.category_name}</span> </td>

                                        <td className="px-4 py-2">
                                            <button
                                                className="bg-blue-500 text-white px-4 py-2 rounded"
                                                onClick={() => openDetailsModal(item?.id)}
                                            >
                                                Add Details
                                            </button>
                                        </td>

                                        <td className="px-4 py-2 text-blue-500 hover:underline cursor-pointer" onClick={() => openEditModal(item.id)}>Edit</td>
                                        <td className="px-4 py-2 text-red-500 hover:underline cursor-pointer">Delete</td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    ))}
                </table>
            </div>

            {/* Add/Edit Details Modal */}
            <Modal isOpen={isDetailsModalOpen} onClose={closeDetailsModal}>
                <h2 className="text-xl font-bold mb-4">Add Details for {selectedJob}</h2>
               <JobAdd selectedJob={selectedJob}/>
                <button className="mt-4 bg-green-500 text-white px-4 py-2 rounded" onClick={closeDetailsModal}>
                    Save Details
                </button>
                <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded" onClick={closeDetailsModal}>
                    Cancel
                </button>
            </Modal>

            {/* Edit Details Modal */}
            <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
                <h2 className="text-xl font-bold mb-4">Add Details for {selectedJob}</h2>
               <JobDetailsEdit editJobs={editJobs}/>
                <button className="mt-4 bg-green-500 text-white px-4 py-2 rounded" onClick={closeEditModal}>
                    Save Details
                </button>
                <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded" onClick={closeEditModal}>
                    Cancel
                </button>
            </Modal>
        </div>
    );
};
