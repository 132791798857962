import React from 'react'
import { Link } from 'react-router-dom'
import AdSense from '../components/ads/AdSense';

export const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
          <h1 className="text-6xl font-bold text-gray-800">404</h1>
          <p className="text-xl text-gray-600 mb-4">Update As soon As possible.</p>
          <Link to="/" className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
            Go Home
          </Link>
          <AdSense/>
        </div>
      );
}
