import React from 'react'
import {Python,Celery,Django,Flask,Metaplotlib,Numpy,Pandas,Requests,Tensorflowandpytorch,programming} from '../Image'
import { Link } from 'react-router-dom'
import AdSense from '../../ads/AdSense'
export const PythonTechnology = () => {
    const data = [
        {name: "coding questions",img: programming,href: "/coding/codingquestions",},
        { name: "Python", img: Python,  href: "/py/python",},
        {name: "Django",img: Django,href: "/py/django",},
        {name: "Flask",img: Flask,href: "/py/flask",},
        {name: "Numpy",img: Numpy, href: "/py/numpy",},
        { name: "Pandas", img: Pandas,  href: "/py/pandas",},
        {name: "Metaplotlib",img: Metaplotlib,href: "/py/metaplotlib",},
        {name: "Request",img: Requests,href: "/py/request",},
        {name: "Celery",img: Celery, href: "/py/celery",},
        { name: "TensorFlow and pyTorch", img: Tensorflowandpytorch,  href: "/py/tensorflowpytorch",},
    ]
  return (
    <div className=" lg:w-custom-widthhome w-custom-width m-auto px-4 ">
    <p className="text-4xl font-serif font-bold mt-12 text-center">Python Technology</p>

    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 justify-items-center mt-12">
        {data?.map((course, index) => (
            <div key={index} className="bg-custom-gray p-6 rounded-lg text-center shadow-custom-shadow transform transition-all duration-300 group w-full">
                <Link to={course?.href}>
                    <img
                        className="mx-auto w-3/4 transition-transform duration-300 transform group-hover:scale-110 rounded-lg"
                        src={course?.img}
                        alt={course?.name}
                    />
                    <p className="mt-4 text-lg font-custom-fontnew font-bold text-gray-800 transition-colors duration-300 group-hover:text-indigo-600">
                        {course?.name}
                    </p>
                </Link>
            </div>
        ))}
    </div>
    <div className="ads-container">
              <AdSense />
              {!window.google && <div className="ads-placeholder"></div>}
            </div>
</div>
  )
}
