import React from 'react';
import { useQuery } from 'react-query';
import { TestListSubCategory } from '../api/Api';
import AdSense from '../../components/ads/AdSense';
import { NotFound } from '../../404page/NotFound';

export const TestSeriesList = ({ testName, setTestPaper }) => {
  // Fetch test series data based on testName
  const { data, isLoading, error } = useQuery(['testSeriesList', testName], () => TestListSubCategory(testName), {
    enabled: !!testName, // only fetch when testName exists
  });

  if (isLoading) return <div className="text-center py-10 text-lg">Loading...</div>;

  return (
    <div className="p-6 bg-gray-50">
      {error ? (
        <div className="w-full">
          <NotFound />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {data?.map((item, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-all duration-300 group w-full"
            >
              <a
                href={`/test-series/test-sub-start/${item?.id}?level=${item?.sub_category}`}
                className="block"
              >
                <div
                  className="h-[120px] flex justify-center items-center rounded-t-lg"
                  style={{ background: 'linear-gradient(to right, #007ACC, #00A9A5)' }}
                >
                  <p className="text-white font-semibold text-xl">Practice Set</p>
                </div>
                <div className="p-5">
                  <p className="text-lg font-bold text-gray-800 transition-colors duration-300 group-hover:text-teal-600">
                    {item?.sub_category}
                  </p>
                  <p className="text-sm text-gray-600 mt-2"> {item.subject_name}</p>
                  {/* <p className="text-sm text-gray-600">Papers: {item.paperNumber}</p> */}
                  {/* <p className="text-sm text-gray-600">Questions: {item.numberOfQuestions}</p> */}
                </div>
              </a>
            </div>
          ))}
        </div>
      )}
      <div className="mt-6">
        <AdSense />
      </div>
    </div>
  );
};
