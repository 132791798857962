import React, { createContext, useContext, useState } from 'react';

// Create Auth Context
const AuthContext = createContext();

// Hook to use Auth Context
export const useAuth = () => useContext(AuthContext);

// AuthProvider Component to wrap around the App
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  // Function to handle login
  const logins = (newToken) => {
    setToken(newToken); // Store token in memory
  };
console.log(token,"token")
  // Function to handle logout
  const logout = () => {
    setToken(null); // Clear token from memory
  };

  return (
    <AuthContext.Provider value={{ token, logins, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
