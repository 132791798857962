import React,{useState} from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { DeleteTestQuestionsList, TestQuestionsList } from '../api/Api';
import { Modal } from '../../jobupdate/jobadmin/Modal';
import { AddQuestionsAdmin } from './addformtest/AddQuestionsAdmin';
import { NotFound } from '../../404page/NotFound';
import { MeApi } from '../../components/account/AccountApi';

export const TestQuestionsAdmin = () => {
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const { data: userdata } = useQuery(['token'], () => MeApi());

  const openJobModal = () => setIsJobModalOpen(true);
  const closeJobModal = () => setIsJobModalOpen(false);
  const { id } = useParams();
  const { data, isLoading, error } = useQuery(['testSeriesDetail', id], () => TestQuestionsList(id));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // if (error) {
  //   return <div>Error loading questions: {error.message}</div>;
  // }

  // if (!data || data.length === 0) {
  //   return <div>No questions available.</div>;
  // }

  const handleEdit = (questionId) => {
    // Add your edit logic here (e.g., redirect to an edit form)
    console.log(`Edit question with ID: ${questionId}`);
  };

  const handleDelete = async (questionId) => {
    try {
      const result = await DeleteTestQuestionsList(questionId); // Assuming this is the API call for deletion
      if (result.status === 200) {
        console.log('Question deleted successfully');
        // Optionally, you can refresh the question list or update the UI after deletion.
      } else {
        console.log('Failed to delete the question');
      }
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };
  return (
    <div className="flex justify-center">
      {
        userdata && userdata.user.user_type === 2 ? (<div className="flex-1 w-full max-w-4xl">
          <button
                    className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
                    onClick={openJobModal}
                >
                    Add Questions
                </button>
    
    
                {
                  error ? <NotFound/> :(
                    <table className="min-w-full bg-white mt-5">
              <thead>
                <tr className="w-full bg-gray-200 text-gray-600 uppercase text-sm leading-normal border border-custom-yellow">
                  <th className="py-3 px-6 text-left border-r border-custom-yellow">Question</th>
                  <th className="py-3 px-6 text-left border-r border-custom-yellow">A</th>
                  <th className="py-3 px-6 text-left border-r border-custom-yellow">B</th>
                  <th className="py-3 px-6 text-left border-r border-custom-yellow">C</th>
                  <th className="py-3 px-6 text-left border-r border-custom-yellow">D</th>
                  <th className="py-3 px-6 text-left border-r border-custom-yellow">correct</th>
                  <th className="py-3 px-6 text-left">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {data.map((item, index) => (
                  <tr key={index} className="border-b hover:bg-gray-100 border border-custom-yellow mt-3">
                    <td className="py-3 px-6 border-r border-custom-yellow"><span className='font-bold text-2xl'>{index+1}</span><span dangerouslySetInnerHTML={{ __html: item.question_text }} /></td>
                    <td className="py-3 px-6 border-r border-custom-yellow">
                     {item.Ans_A}
                    </td>
                    <td className="py-3 px-6 border-r border-custom-yellow">
                     {item.Ans_B}
                    </td>
                    <td className="py-3 px-6 border-r border-custom-yellow">
                     {item.Ans_c}
                    </td>
                    <td className="py-3 px-6 border-r border-custom-yellow">
                     {item.Ans_D}
                    </td>
                    <td className="py-3 px-6 border-r border-custom-yellow">
                     {item.correct_answer}
                    </td>
                    <td className="py-3 px-6 flex space-x-2">
                      <button
                        onClick={() => handleEdit(item.id)} // Assuming item has an id
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(item?.id)} // Assuming item has an id
                        className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-200"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
                  )
                }
            
    
            <Modal isOpen={isJobModalOpen} onClose={closeJobModal}>
              <AddQuestionsAdmin id={id}/>
            <button className="mt-4 bg-green-500 text-white px-4 py-2 rounded" onClick={closeJobModal}>
                        Save Job
                    </button>
                    <button className="mt-4 bg-red-500 text-white px-4 py-2 rounded" onClick={closeJobModal}>
                        Cancel
                    </button>
            </Modal>
          </div>):(
            <div>
              <h1 className="text-3xl font-bold text-center py-4">Unauthorized</h1>
            </div>
          )
      }
      
    </div>
  );
};
