import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const AdSense = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("Adsense error: ", e);
    }
  }, []);

  return (
    <div>
      <Helmet>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2986457178303457"
     crossorigin="anonymous"></script>
       
        <script>
          {`
            (adsbygoogle = window.adsbygoogle || []).push({
              google_ad_client: "ca-pub-2986457178303457",
              enable_page_level_ads: true
            });
          `}
        </script>
      </Helmet>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-2986457178303457"
        data-ad-slot="2986457178303457"
        data-ad-format="auto"
      ></ins>
    </div>
  );
};

export default AdSense;
