import { chemistry } from '../../../components/home/Image';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ChemistryAllClass } from '../physics/api/Api';
import AdSense from '../../../components/ads/AdSense';

export const ChemistryClass = () => {
    const { data } = useQuery('chemistryclass', ChemistryAllClass);
    const navigate = useNavigate();
  
    const handleNavigate = (id,class_name) => {
      navigate('/chemistry', { state: { id,class_name } });
    };
  
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Chemistry Classes</h1>
        <div className="flex flex-wrap -mx-4">
          {data?.data?.map((item) => (
            <div key={item.id} className="w-full md:w-1/2 xl:w-1/3 p-4">
              <div className="bg-white rounded shadow-md p-4 cursor-pointer" onClick={() => handleNavigate(item.id,item?.class_name)}>
                <img src={chemistry} alt={`Class_${item.class_name}_Image`} className="w-full h-64 object-cover mb-4" />
                <p className="text-2xl font-bold mb-2">{item.class_name}</p>
              </div>
            </div>
          ))}
        </div>
        <div>
          <AdSense/>
        </div>
      </div>
    );
  };
  