import React, { useState } from 'react';
import { AddTestPaperList1 } from '../../api/Api';

export const AddTestPaperList = ({ id }) => {
  // State to handle form inputs
  const [formData, setFormData] = useState({
    practice_set: '',
    syllabus_name: '',
    description: '',
    number_of_question: '',
    meta_title: '',
    meta_description: '',
    meta_tags: '',
    exam_sub_category: id
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await AddTestPaperList1(formData);
      console.log(result.status, 'result');
      console.log('Form submitted with payload:', formData);
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-8 p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Add Test Paper</h2>
      <form onSubmit={handleSubmit}>
        <label className="block mb-2 font-bold">Practice Set</label>
        <input
          type="text"
          value={formData.practice_set}
          name="practice_set"
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
          placeholder="Practice Set Name"
        />

        <label className="block mb-2 font-bold">Syllabus Name</label>
        <input
          type="text"
          value={formData.syllabus_name}
          name="syllabus_name"
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
          placeholder="Syllabus Name"
        />

        <label className="block mb-2 font-bold">Description</label>
        <textarea
          value={formData.description}
          name="description"
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
          placeholder="Description (optional)"
        ></textarea>

        <label className="block mb-2 font-bold">Number of Questions</label>
        <input
          type="number"
          value={formData.number_of_question}
          name="number_of_question"
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
          placeholder="Number of Questions"
        />

        <label className="block mb-2 font-bold">Meta Title</label>
        <input
          type="text"
          value={formData.meta_title}
          name="meta_title"
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
          placeholder="Meta Title (optional)"
        />

        <label className="block mb-2 font-bold">Meta Description</label>
        <textarea
          value={formData.meta_description}
          name="meta_description"
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
          placeholder="Meta Description (optional)"
        ></textarea>

        <label className="block mb-2 font-bold">Meta Tags</label>
        <input
          type="text"
          value={formData.meta_tags}
          name="meta_tags"
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
          placeholder="Meta Tags (optional)"
        />

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          Submit Test Paper
        </button>
      </form>
    </div>
  );
};
