import React from 'react'
import { ProjectCard } from './ProjectCard'

export const MiniProject = () => {
  return (
    <div>
        <ProjectCard/>
    </div>
  )
}
