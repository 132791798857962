import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

export const Card = () => {
    const videoRef = useRef(null);
    const [isCameraOn, setIsCameraOn] = useState(false);
    const navigate = useNavigate()
    // Function to request full screen
    const openFullScreen = async () => {
      const docElm = document.documentElement; // Get the HTML document
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) { // Firefox
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        docElm.webkitRequestFullscreen();
      } else if (docElm.msRequestFullscreen) { // IE/Edge
        docElm.msRequestFullscreen();
      }
    };
  
    // Function to open the camera
    const openCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoRef.current.srcObject = stream;
        setIsCameraOn(true);
      } catch (error) {
        console.error('Error accessing the camera:', error);
      }
    };
 
    // Combined function to handle full screen and camera access
    const handleFullScreenAndCamera = async () => {
      await openFullScreen(); // Make page full screen
      openCamera(); // Open the camera feed
      navigate("/")
    };
  
    return (
      <div className="h-screen flex flex-col justify-center items-center bg-gray-900">
        {!isCameraOn && (
          <button
            onClick={handleFullScreenAndCamera}
            className="px-6 py-3 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 focus:outline-none"
          >
            Open Full Screen & Camera
          </button>
        )}
  
        {isCameraOn && (
          <video
            ref={videoRef}
            autoPlay
            className="w-full h-full object-cover"
          />
        )}
      </div>
    );
  };
  