import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { JobNameListShow, JobNameAdds } from '../api/Api'; // Ensure these are properly imported

export const JobNameAdd = () => {
    const queryClient = useQueryClient();

    // Form state
    const [jobDetails, setJobDetails] = useState('');
    const [selectedJobName, setSelectedJobName] = useState('');

    // Fetch job names for the select dropdown
    const { data: jobNames, isLoading, error } = useQuery(['joblist'], JobNameListShow);

    // Mutation for adding a new job name
    const mutation = useMutation((newJobName) => JobNameAdds(newJobName), {
        onSuccess: () => {
            queryClient.invalidateQueries(['joblist']); // Refresh the job names list
        },
        onError: (error) => {
            console.error('Error adding job name:', error);
        },
    });

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            category_name: selectedJobName,
            name: jobDetails,
        };
        mutation.mutate(formData); // Call the mutation to add the new job name
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Add Job Name</h2>

            {isLoading ? (
                <p>Loading job names...</p>
            ) : error ? (
                <p>Error fetching job names: {error.message}</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block mb-2 font-bold">Select Job Category</label>
                        <select
                            value={selectedJobName}
                            onChange={(e) => setSelectedJobName(e.target.value)}
                            className="w-full p-2 border rounded"
                        >
                            <option value="">Select a job name</option>
                            {jobNames?.map((job, index) => (
                                <option key={index} value={job.id}>
                                    {job.category_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2 font-bold">Job Details</label>
                        <textarea
                            value={jobDetails}
                            onChange={(e) => setJobDetails(e.target.value)}
                            className="w-full h-32 border p-2 rounded"
                            placeholder="Enter job details here..."
                        />
                    </div>

                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                        disabled={mutation.isLoading}
                    >
                        {mutation.isLoading ? 'Submitting...' : 'Submit Job Details'}
                    </button>
                </form>
            )}
        </div>
    );
};

