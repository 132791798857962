import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AngularData } from '../../api/Api';
import { NotFound } from '../../../../404page/NotFound';
import AdSense from '../../../../components/ads/AdSense';

export const Angular = () => {
  const { pathname } = useLocation();
  const notesRef = useRef(null);
  
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error } = useQuery(['data', page], () => AngularData(page));
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
    if (notesRef.current) {
      notesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
    if (notesRef.current) {
      notesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Loading state
  if (isLoading) return <div>Loading...</div>;

  // Error state
  if (isError) return <div>Error: {error.message}</div>;

  // If data is an empty string or undefined, render NotFound
  if (!data?.data || data.data === "") return <NotFound />;

  return (
    <div className="main-container">
      <div className="container">
        <p className="breadcrumb" ref={notesRef}>
          Home » <span className="breadcrumb-link">Programming</span> » <span className="breadcrumb-current">What is Angular and What Can It Do?</span>
        </p>

        {data.data.map((alldata, index) => (
          <div key={alldata.id} className="qa-container">
            <p className="react-question text-gray-600">Q{index + 1}. {alldata.questions}</p>
            <p className="react-answer text-gray-600">Answer: {alldata.answer}</p>
            
            {/* AdSense or placeholder */}
            <div className="ads-container">
              <AdSense />
              {!window.google && <div className="ads-placeholder"></div>}
            </div>

            {alldata.code && (
              <div className="code-container">
                <code>
                  <pre>{alldata.code}</pre>
                </code>
              </div>
            )}
          </div>
        ))}

        <div className="pagination">
          <button
            type="button"
            onClick={handlePreviousPage}
            disabled={page === 1}
            className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded ${page === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}>
            Previous
          </button>
          <span>{`Page ${page} of ${data.total_pages}`}</span>
          <button
            type="button"
            onClick={handleNextPage}
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${page === data.total_pages ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={page === data.total_pages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
