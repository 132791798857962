import React from 'react';
import { Link } from 'react-router-dom';
import { Javascript, Angular, Chart, Css, D3js, Express, Html, Jquery, Moment, Nest, Next, Node, Reactjs, Redux, Three, Typescript, Vue, programming } from '../Image';
import AdSense from '../../ads/AdSense';

export const WebTechnology = () => {
    const data = [
        { name: "Objective Test", img: programming, href: "/objectivecoding" },
        { name: "coding questions", img: programming, href: "/coding/codingquestions" },
        { name: "JavaScript", img: Javascript, href: "/javascript/javascript" },
        { name: "React", img: Reactjs, href: "/javascript/react" },
        { name: "Angular", img: Angular, href: "/javascript/angular" },
        { name: "Typescript", img: Typescript, href: "/javascript/typescript" },
        { name: "Redux", img: Redux, href: "/javascript/redux" },
        { name: "Vue js", img: Vue, href: "/javascript/vue" },
        { name: "JQuery", img: Jquery, href: "/javascript/jquery" },
        { name: "Node js", img: Node, href: "/javascript/nodejs" },
        { name: "Express js", img: Express, href: "/javascript/expressjs" },
        { name: "Next js", img: Next, href: "/javascript/nextjs" },
        { name: "Nest js", img: Nest, href: "/javascript/nestjs" },
        { name: "Moment js", img: Moment, href: "/javascript/momentjs" },
        { name: "D3js", img: D3js, href: "/javascript/d3js" },
        { name: "Three js", img: Three, href: "/javascript/threejs" },
        { name: "Chart js", img: Chart, href: "/javascript/chartjs" },
        { name: "HTML", img: Html, href: "/javascript/html" },
        { name: "CSS", img: Css, href: "/javascript/css" }
    ];

    return (
        <div className=" lg:w-custom-widthhome w-custom-width m-auto px-4 ">
            <p className="text-4xl font-serif font-bold mt-12 text-center">Web Technology</p>

            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 justify-items-center mt-12">
                {data?.map((course, index) => (
                    <div key={index} className="bg-custom-gray p-6 rounded-lg text-center shadow-custom-shadow transform transition-all duration-300 group w-full">
                        <Link to={course?.href}>
                            <img
                                className="mx-auto w-3/4 transition-transform duration-300 transform group-hover:scale-110 rounded-lg"
                                src={course?.img}
                                alt={course?.name}
                            />
                            <p className="mt-4 text-lg font-custom-fontnew font-bold text-gray-800 transition-colors duration-300 group-hover:text-indigo-600">
                                {course?.name}
                            </p>
                        </Link>
                    </div>
                ))}
            </div>
            <div className="ads-container">
              <AdSense />
              {!window.google && <div className="ads-placeholder"></div>}
            </div>
        </div>

    );
};
