import React from 'react'

export const Home = () => {
  return (
    
    <div>
    <div className='w-full h-[100vh] flex justify-center mt-0'>
    <img className='w-full h-[100vh] object-cover' src='https://cdn.pixabay.com/photo/2024/02/21/15/11/employees-8587901_1280.png' alt='Employees Office Team Work Tutor' />
</div>


</div>
  )
}
