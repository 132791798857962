import axios from 'axios';
import { useAuth } from './AuthContext';
// import { useAuth } from './AuthContext'; // Import Auth Context to access token

const baseUrl = process.env.REACT_APP_BASE_URL;

export const register = async (values) => {
  try {
    const response = await axios.post(`${baseUrl}/api/user/register/`, values);
    return response.data;
  } catch (error) {
    console.error('Registration error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchProtectedData = async () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { token } = useAuth(); 

  try {
    const response = await axios.get(`${baseUrl}/api/protected/`, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
      withCredentials: true, 
    });
    return response.data;
  } catch (error) {
    console.error('Fetching protected data error:', error);
    throw error;
  }
};



export const LoginApi = async(values)=>{
  try{
    const response = await axios.post(`${baseUrl}/api/user/login/`, values);
    // const { token } = response.data;

    // Store the token in localStorage
    
    return response
  }catch(error){
    console.error('Login error:', error);
  }
}


// export const MeApi = async(token)=>{
//   try {
//     const response = await axios.get(`${baseUrl}/api/user/protected/`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`
//       }})

//       return response.data
//   } catch (error) {
//     throw error
//   }
// }



export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refresh');
  if (!refreshToken) {
      throw new Error('No refresh token available');
  }

  try {
      const response = await axios.post(`${baseUrl}/api/user/refresh/`, {
          refresh: refreshToken,
      });

      const newAccessToken = response.data.access;
      localStorage.setItem('accessToken', newAccessToken);

      return newAccessToken;
  } catch (error) {
      // console.error('Failed to refresh token', error);
      throw error;
  }
};



export const MeApi = async () => {
  let accessToken = localStorage.getItem('authToken');
  
  try {
      // Make the request with the access token
      const response = await axios.get(`${baseUrl}/api/user/protected/`, {
          headers: {
              Authorization: `Bearer ${accessToken}`,
          },
      });
      return response.data;
  } catch (error) {
      if (error.response && error.response.status === 401) {
          // If access token expired, refresh it
          accessToken = await refreshAccessToken();

          // Retry the request with the new access token
          const response = await axios.get(`${baseUrl}/api/user/protected/`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
          });
          return response.data;
      }

      throw error;  // Rethrow other errors
  }
};